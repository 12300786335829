'use client'
import { useState } from 'react'
import Image from "next/image"
import { Combobox } from "@headlessui/react"
import { RiArrowDropDownLine } from 'react-icons/ri'
import tailwindConfig from '@tailwind.config'

const HeroSearchBts = ({ onChange, btsList }) => {
    const [query, setQuery] = useState('')
    const [selectedBts, setSelectedBts] = useState(btsList[0])

    const filteredBts =
        query === ''
            ? btsList
            : btsList.filter((item) => (
                item.stationName.toLowerCase().replace(/\s+/g, '').includes(query.toLocaleLowerCase().replace(/\s+/g, '')
                )))

    const selectTextOnClick = (e) => {
        e.target.select();
    };
    return (
        <div className='flex flex-col w-full'>
            <Combobox
                value={selectedBts.stationName}
                onChange={
                    (value) => {
                        setSelectedBts(value)
                        onChange(value)
                    }

                }
            >
                <div className="relative w-full">
                    <div className='z-1 relative w-full cursor-default overflow-hidden rounded-l-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                        <Combobox.Input onClick={selectTextOnClick} onChange={(event) => setQuery(event.target.value)} required className='w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0' aria-label="BTS/MRT Search Input" onFocus={() => {
                        }} />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center" id="mrt-dropdown" aria-label="BTS/MRT Dropdown Button" >
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <RiArrowDropDownLine
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                    size={28}
                                />
                            </span>
                        </Combobox.Button>
                    </div>
                    <Combobox.Options
                        as="ul"
                        className='z-50 left-0 right-0 absolute bg-white h-auto max-h-[150px] w-[250px] overflow-y-auto shadow-md rounded-md py-1'
                    >
                        {filteredBts.map((bts) => (
                            <Combobox.Option
                                key={bts.id}
                                value={bts}
                                className='py-2 px-4 cursor-pointer color-secondary z-50 flex flex-row items-center'
                            >
                                {bts.id === '' ? (
                                    <span className='w-1/6 flex flex-row justify-between'>
                                        <Image
                                            src="/images/icons/bts.png"
                                            width={18}
                                            height={18}
                                            alt="BTS icon"
                                        />
                                        <Image
                                            src="/images/icons/mrt.png"
                                            width={23}
                                            height={18}
                                            alt="BTS icon"
                                        />
                                    </span>
                                ) : (
                                    <span className={`w-1/6 rounded-md p-1 text-light text-sm mr-1 text-center`} style={{ backgroundColor: bts.colorCode }}>{bts.type}</span>
                                )}
                                <span className="ml-2 text-maincolor">{bts.stationName}</span>
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </div>
            </Combobox>
        </div >
    )
}

export default HeroSearchBts