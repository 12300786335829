'use client'
import React, { useState, useEffect } from 'react'
import { FaUser } from 'react-icons/fa'
import { MdOutlinePhoneIphone, MdEmail } from 'react-icons/md'
import { AiFillWechat } from 'react-icons/ai'
import { FaLine } from 'react-icons/fa'
import { sendPersonalForm } from '@services/sendPersonalForm'
import { useSession } from 'next-auth/react'
import tailwindConfig from '@tailwind.config'
import { Combobox, Listbox } from "@headlessui/react"
import { countryCodes } from '@constants/countryCodes'
import { RiArrowDropDownLine, RiLayoutGridLine } from 'react-icons/ri'
import { serviceSaleTypes, serviceCategoryTypes, serviceCondoRoomTypes, serviceHouseRoomTypes, serviceLandUnit } from '@constants';
import { preferredContact } from '@constants/preferredContact'
import { RadioGroup } from '@headlessui/react'
import { TbReportMoney } from 'react-icons/tb'
import Image from 'next/image'
import { FaCheckCircle } from "react-icons/fa";

const PersonalizedServiceForm = ({ dictionary, lang }) => {
    const { data: session } = useSession()

    const [sale, setSale] = useState(serviceSaleTypes[lang][0].value)
    // let [addChecked, setAddChecked] = useState(serviceSaleTypes[0].value)

    const [category, setCategory] = useState(serviceCategoryTypes[lang][0])
    // let [addChecked, setAddChecked] = useState(serviceCategoryTypes[0].value)

    const [room, setRoom] = useState(serviceCondoRoomTypes[lang][0])
    // let [addChecked, setAddChecked] = useState(serviceCondoRoomTypes[0].value)

    const [area, setArea] = useState('')
    const [landUnit, setLandUnit] = useState(serviceLandUnit[lang][0])
    const [budget, setBudget] = useState('')
    const [purpose, setPurpose] = useState('')


    const [type, setType] = useState('')
    const [contact, setContact] = useState(preferredContact[lang][0])


    const [userId, setUserId] = useState(null)
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [line, setLine] = useState('')
    const [wechat, setWechat] = useState('')
    const [message, setMessage] = useState(``)
    const [hasEnquiry, setHasEnquiry] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(countryCodes[0].code)
    const [query, setQuery] = useState('')

    const filteredCountries =
        query === ''
            ? countryCodes
            : countryCodes.filter((country) => {
                return country.country.toLowerCase().includes(query.toLowerCase()) || country.code.toLowerCase().includes(query.toLowerCase())
            })

    useEffect(() => {
        const setUserData = () => {
            if (session?.user) {
                // console.log(session?.user)
                setUserId(session?.user.id)
                setName(session?.user.name)
                setPhone(session?.user.phone)
                setSelectedCountry(session?.user.phonePrefix || countryCodes[0].code)
                setEmail(session?.user.email)
            }
        }
        setUserData()
    }, [session])

    const handleForm = async (e) => {
        e.preventDefault()
        await sendPersonalForm(userId, name, selectedCountry, phone, email, message, sale, category, contact, line, wechat, budget, room, purpose, area, landUnit)
        setHasEnquiry(true)
    }

    const selectTextOnClick = (e) => {
        e.target.select();
    };

    return (
        <>
            {!hasEnquiry ? (
                <div id='personalized-service' className='xl:mb-0 pb-3 xl:border-b-0 border-b-[1px] border-b-forth flex flex-col sm:flex-row'>
                    <div className='flex flex-col sm:w-1/2 w-full mb-2'>
                        <div className='w-full'>
                            <h2 className='text-maincolor font-bold text-xl'>{dictionary.homeSearchTitle}</h2>
                            <p className="text-secondary">{dictionary.provideDetails}</p>
                        </div>
                        <div className='w-full border-none sm:border-l-[1px]'>
                            <form onSubmit={handleForm}>
                                <h3 className='text-md text-maincolor mt-2'>{dictionary.lookingFor}</h3>
                                <div className="w-full">
                                    <RadioGroup value={category} onChange={(value) => {
                                        setCategory(value)
                                    }}
                                        className='w-full py-1 mt-1 flex flex-row'
                                    >
                                        {serviceCategoryTypes[lang].map((categoryType) => (
                                            <RadioGroup.Option
                                                key={categoryType.value}
                                                value={categoryType}
                                                checked={categoryType === category}
                                                className={({ active, checked }) =>
                                                    `${active
                                                        ? ''
                                                        : ''
                                                    }
                                    ${checked ? 'bg-maincolor text-light' : 'bg-light'
                                                    }
                                    w-full relative flex cursor-pointer rounded-lg px-2 py-2 shadow-md focus:outline-none`
                                                }
                                            >
                                                {({ active, checked }) => (
                                                    <>
                                                        <div className="flex w-full items-center justify-between">
                                                            <div className="flex items-center">
                                                                <div className="text-sm text-center">
                                                                    <RadioGroup.Label
                                                                        as="p"
                                                                        className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                                            }`}
                                                                    >
                                                                        {categoryType.title}
                                                                    </RadioGroup.Label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        ))}
                                    </RadioGroup>
                                </div>
                                {(category.value === 'condo') && (
                                    <>
                                        <span className='text-sm text-secondary'>{dictionary.numberOfBedrooms}</span>
                                        <div className="w-full">
                                            <RadioGroup value={room} onChange={(value) => {
                                                setRoom(value)
                                            }}
                                                className='w-full py-1 mt-1 flex flex-row'
                                            >
                                                {serviceCondoRoomTypes[lang].map((roomType) => (
                                                    <RadioGroup.Option
                                                        key={roomType.value}
                                                        value={roomType}
                                                        checked={roomType === room}
                                                        className={({ active, checked }) =>
                                                            `${active
                                                                ? ''
                                                                : ''
                                                            }
                                    ${checked ? 'bg-maincolor text-light' : 'bg-light'
                                                            }
                                    w-full relative flex cursor-pointer rounded-lg px-2 py-2 shadow-md focus:outline-none`
                                                        }
                                                    >
                                                        {({ active, checked }) => (
                                                            <>
                                                                <div className="flex w-full items-center justify-between">
                                                                    <div className="flex items-center">
                                                                        <div className="text-sm text-center">
                                                                            <RadioGroup.Label
                                                                                as="p"
                                                                                className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                                                    }`}
                                                                            >
                                                                                {roomType.title}
                                                                            </RadioGroup.Label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                            </RadioGroup>
                                        </div>
                                    </>
                                )}
                                {(category.value === 'house') && (
                                    <>
                                        <span className='text-sm text-secondary'>{dictionary.numberOfBedrooms}</span>
                                        <div className="w-full">
                                            <RadioGroup value={room} onChange={(value) => {
                                                setRoom(value)
                                            }}
                                                className='w-full py-1 mt-1 flex flex-row'
                                            >
                                                {serviceHouseRoomTypes[lang].map((roomType) => (
                                                    <RadioGroup.Option
                                                        key={roomType.value}
                                                        value={roomType}
                                                        checked={roomType === room}
                                                        className={({ active, checked }) =>
                                                            `${active
                                                                ? ''
                                                                : ''
                                                            }
                                    ${checked ? 'bg-maincolor text-light' : 'bg-light'
                                                            }
                                    w-full relative flex cursor-pointer rounded-lg px-2 py-2 shadow-md focus:outline-none`
                                                        }
                                                    >
                                                        {({ active, checked }) => (
                                                            <>
                                                                <div className="flex w-full items-center justify-between">
                                                                    <div className="flex items-center">
                                                                        <div className="text-sm text-center">
                                                                            <RadioGroup.Label
                                                                                as="p"
                                                                                className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                                                    }`}
                                                                            >
                                                                                {roomType.title}
                                                                            </RadioGroup.Label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                            </RadioGroup>
                                        </div>
                                    </>
                                )}

                                {(category.value === 'land') && (
                                    <>

                                        <label className='text-secondary text-sm'>{dictionary.landSize}</label>
                                        <div className='flex flex-row w-full py-1'>
                                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                                <RiLayoutGridLine
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={28}
                                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                                />
                                                <input
                                                    className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                                    placeholder={dictionary.landSizePlaceholder}
                                                    type='number'
                                                    name='area'
                                                    value={area}
                                                    onChange={(e) => setArea(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <Listbox value={landUnit} onChange={setLandUnit}>
                                                <div className='z-1 relative w-full cursor-default rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:shadow-md focus:outline-none  sm:text-sm">
                                                        <span className="block truncate">{landUnit.name}</span>
                                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <RiArrowDropDownLine
                                                                color={tailwindConfig.theme.extend.colors.secondary}
                                                                size={28}
                                                            />
                                                        </span>
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                                                        {serviceLandUnit[lang].map((cont, contIdx) => (
                                                            <Listbox.Option
                                                                key={contIdx}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-100 text-maincolor' : 'text-secondary'
                                                                    }`
                                                                }
                                                                value={cont}
                                                            >
                                                                {({ selected }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {cont.name}
                                                                        </span>
                                                                        {selected ? (
                                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                                {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                        </div>
                                    </>
                                )}
                                {(category.value === 'commercial') &&
                                    <>
                                        <label className='text-secondary text-sm'>{dictionary.businessPurpose}</label>
                                        <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                            <TbReportMoney
                                                color={tailwindConfig.theme.extend.colors.secondary}
                                                size={28}
                                                className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                            />
                                            <input
                                                className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                                placeholder={dictionary.businessPurposePlaceholder}
                                                type='text'
                                                name='purpose'
                                                value={purpose}
                                                onChange={(e) => setPurpose(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </>


                                }

                                <label className='text-secondary text-sm'>{dictionary.budget}</label>
                                <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                    <TbReportMoney
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                        size={28}
                                        className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                    />
                                    <input
                                        className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                        placeholder={dictionary.budgetPlaceholder}
                                        type='number'
                                        name='budget'
                                        value={budget}
                                        onChange={(e) => setBudget(e.target.value)}
                                        required
                                    />
                                </div>
                                <label className='text-secondary text-sm'>{dictionary.additionalInformation}</label>
                                <div className='w-full py-1'>
                                    <textarea
                                        className='w-full border-[1px] border-forth text-secondary rounded-md p-2'
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        rows={3}
                                        required
                                        placeholder={dictionary.additionalInformationPlaceholder}
                                    />
                                </div>
                                <label className='text-secondary text-sm'>{dictionary.name}</label>
                                <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                    <FaUser
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                        size={28}
                                        className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                    />
                                    <input
                                        className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                        placeholder={dictionary.namePlaceholder}
                                        type='text'
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <label className='text-secondary text-sm'>{dictionary.preferredContact}</label>
                                <Listbox value={contact} onChange={setContact}>
                                    <div className='z-1 relative w-full cursor-default rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:shadow-md focus:outline-none  sm:text-sm">
                                            <span className="block truncate">{contact.name}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <RiArrowDropDownLine
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={28}
                                                />
                                            </span>
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                                            {preferredContact[lang].map((cont, contIdx) => (
                                                <Listbox.Option
                                                    key={contIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-100 text-maincolor' : 'text-secondary'
                                                        }`
                                                    }
                                                    value={cont}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                    }`}
                                                            >
                                                                {cont.name}
                                                            </span>
                                                            {selected ? (
                                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                    {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>
                                {(contact.value == 'phone' || contact.value == 'whatsapp' || contact.value == 'viber') && (
                                    <>
                                        <label className='text-secondary text-sm'>{dictionary.phone}</label>
                                        <div className='flex flex-row w-full py-1'>
                                            {/* <CountryCode className="w-1/4" /> */}
                                            <Combobox className="w-1/4"
                                                value={selectedCountry}
                                                onChange={setSelectedCountry}
                                            >
                                                <div className="relative w-full">
                                                    <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                                        <Combobox.Input onClick={selectTextOnClick} onChange={(event) => setQuery(event.target.value)} required className='w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0' id="phone-number" />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" id="country-code-dropdown" aria-label="Country Code Dropdown Button" >
                                                            <RiArrowDropDownLine
                                                                color={tailwindConfig.theme.extend.colors.secondary}
                                                                size={28}
                                                            />
                                                        </Combobox.Button>
                                                    </div>
                                                    <Combobox.Options
                                                        as="div" // Render the Combobox.Options as an unordered list (ul) to contain the options
                                                        className='z-50 left-0 right-0 absolute bg-white h-auto w-[300px] max-h-[200px] overflow-y-auto shadow-md rounded-md py-1'
                                                    >
                                                        {filteredCountries.map((country) => (
                                                            <Combobox.Option
                                                                key={country.id}
                                                                value={country.code}
                                                                className='py-2 px-4 cursor-pointer color-secondary z-50'
                                                            >
                                                                {country.code} {country.country}
                                                            </Combobox.Option>
                                                        ))}
                                                    </Combobox.Options>
                                                </div>
                                            </Combobox>
                                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                                <MdOutlinePhoneIphone
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={30}
                                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                                />
                                                <input
                                                    className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                                    placeholder={dictionary.phonePlaceholder}
                                                    required
                                                    type='text'
                                                    name='phone'
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {(contact.value == 'line') && (
                                    <>
                                        <label className='text-secondary text-sm'>{dictionary.line}</label>
                                        <div className='w-full py-1'>
                                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                                <FaLine
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={30}
                                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                                />
                                                <input
                                                    className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                                    placeholder={dictionary.linePlaceholder}
                                                    type='line'
                                                    name='line'
                                                    value={line}
                                                    onChange={(e) => setLine(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {(contact.value == 'wechat') && (
                                    <>
                                        <label className='text-secondary text-sm'>{dictionary.wechat}</label>
                                        <div className='w-full py-1'>
                                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                                <AiFillWechat
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={30}
                                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                                />
                                                <input
                                                    className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                                    placeholder={dictionary.wechatPlaceholder}
                                                    type='wechat'
                                                    name='wechat'
                                                    value={wechat}
                                                    onChange={(e) => setWechat(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                                <label className='text-secondary text-sm'>{dictionary.email}</label>
                                <div className='w-full py-1'>
                                    <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                        <MdEmail
                                            color={tailwindConfig.theme.extend.colors.secondary}
                                            size={30}
                                            className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                        />
                                        <input
                                            className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                            placeholder={dictionary.emailPlaceholder}
                                            type='email'
                                            name='email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <button className='w-full p-2 bg-third text-light font-bold rounded-md' type='submit'>{dictionary.send}</button>
                            </form>
                        </div>
                    </div>
                    <div className='sm:w-1/2 hidden h-full z-0 relative overflow-hidden sm:flex justify-end items-center pt-5'>
                        {/* <div className="relative h-full"> */}
                        <div className='relative w-[400px] h-[800px] rounded-md overflow-hidden'>
                            <Image
                                src={'/images/contactform.avif'}
                                // height={800}
                                // width={400}
                                fill={true}
                                className='object-contain'
                                // priority={"true"}
                                // style={{
                                //     objectFit: 'cover'
                                // }}
                                alt="contact us"
                            />
                        </div>
                        {/* </div> */}
                    </div>

                </div >) : (
                <div className='xl:mb-0 pb-3 flex flex-col sm:flex-row'>
                    <div className='sm:w-1/2 w-full flex flex-col items-center justify-center'>
                        <h2 className='text-maincolor font-bold text-xl'>{dictionary.formSent}</h2>
                        <p className='text-secondary'>{dictionary.formSentSubtitle}</p>
                        <FaCheckCircle
                            color={tailwindConfig.theme.extend.colors.secondary}
                            size={48}
                            className='mt-6'
                        />
                    </div>
                    <div className='sm:w-1/2 w-full items-center justify-center sm:flex hidden'>
                        <div className='relative w-[400px] h-[800px] rounded-md overflow-hidden'>
                            <Image
                                src={'/images/contactform.avif'}
                                // height={800}
                                // width={400}
                                fill={true}
                                className='object-contain'
                                // priority={"true"}
                                // style={{
                                //     objectFit: 'cover'
                                // }}
                                alt="contact us"
                            />
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default PersonalizedServiceForm