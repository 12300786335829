'use client'
import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import LargeSwiperImage from '@components/LargeSwiperImage'
import SmallSwiperImage from '@components/SmallSwiperImage'
import 'swiper/css';


const DistrictCardSlider = ({ title, description, districts, dictionary, lang }) => {

    return (
        <section className='pl-3 mt-5 2xl:w-8/12 mx-auto'>
            <h2 className='text-maincolor font-bold text-xl'>{title}</h2>
            <p className="text-secondary">{description}</p>
            <Swiper
                spaceBetween={0}
                slidesPerView={"auto"}
                className="mt-2 block sm:hidden"
            >
                <SwiperSlide className="district-swiper-slide w-[300px]">
                    <LargeSwiperImage title={dictionary.bangkok} imageSrc={'bangkok'} />
                </SwiperSlide>
                {districts && districts.slice(0, Math.ceil(districts.length / 2)).map((district, index) => (
                    <SwiperSlide className="district-swiper-slide" key={index}>
                        <SmallSwiperImage
                            title={districts[index * 2]?.subdistrict}
                            slug={districts[index * 2]?.slug}
                            route='districts'
                            imageSrc={districts[index * 2]?.coverImage}
                            index={index * 2}
                        />
                        {index * 2 + 1 < districts.length ? (
                            <SmallSwiperImage
                                title={districts[index * 2 + 1]?.subdistrict}
                                slug={districts[index * 2 + 1]?.slug}
                                route='districts'
                                imageSrc={districts[index * 2 + 1]?.coverImage}
                                index={index * 2 + 1}
                            />
                        )
                            : null
                        }
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    )
}

export default DistrictCardSlider